export const CHANGE_HIDDEN_INPUT = "CHANGE_HIDDEN_INPUT";
export const UPDATE_INSCRIPTION_ENTITE_FORMULAIRE = "UPDATE_INSCRIPTION_ENTITE_FORMULAIRE";
export const UPDATE_INSCRIPTION_RL_FORMULAIRE = "UPDATE_INSCRIPTION_RL_FORMULAIRE";
export const UPDATE_INSCRIPTION_RDP_FORMULAIRE = "UPDATE_INSCRIPTION_RDP_FORMULAIRE";

export const LOGIN_INPUT_CHANGE = "USERNAME_CHANGE_INPUT";
export const LOGIN = "LOGIN";
export const REINIT_LOGIN_REDIRECT_PATH = "REINIT_LOGIN_REDIRECT_PATH";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGIN_REPLACE = "LOGIN_REPLACE";
export const LOGIN_START = "LOGIN_START";
export const LOGOUT = "LOGOUT";
export const LOGIN_CHECK_STATE = "LOGIN_CHECK_STATE";
export const LOGIN_UPDATE_RELOADING = "LOGIN_UPDATE_RELOADING";
export const MAJ_MENU_ITEMS = "MAJ_MENU_ITEMS";
export const RELOAD_SITE = "RELOAD_SITE";

export const SEARCH_GLN_ASSUJETTI = "SEARCH_GLN_ASSUJETTI";
export const SEARCH_GLN_ASSUJETTI_START = "SEARCH_GLN_ASSUJETTI_START";
export const SEARCH_GLN_ASSUJETTI_END = "SEARCH_GLN_ASSUJETTI_END";
export const SEARCH_GLN_ASSUJETTI_FAILED = "SEARCH_GLN_ASSUJETTI_FAILED";
export const UPDATE_RESULT_GLN = "UPDATE_RESULT_GLN";
export const UPDATE_RESULT_GLN_AND_ACTIVE_BUTTON = "UPDATE_RESULT_GLN_AND_ACTIVE_BUTTON";
export const UPDATE_TYPE_SEARCH_GLN = "UPDATE_TYPE_SEARCH_GLN";
export const UPDATE_FLAG_FIRST_SEARCH = "UPDATE_FLAG_FIRST_SEARCH";
export const UPDATE_FLAG_IS_CALLED_DECLARATION = "UPDATE_FLAG_IS_CALLED_DECLARATION";

// Common ACTION
export const UPDATE_DISABLE_BUTTON_VALIDE = "UPDATE_DISABLE_BUTTON_VALIDE";
export const LOADING = "LOADING";
export const SET_FIL_ARIANE_KEY_MESSAGE = "SET_FIL_ARIANE_KEY_MESSAGE";
export const CHECK_SIRET = "CHECK_SIRET";
export const CHECK_SIRET_SUCCESS = "CHECK_SIRET_SUCCESS";
export const INTEGRER_EXCEL_FROM_FILEPOND = "INTEGRER_EXCEL_FROM_FILEPOND";
export const UPDATE_FILEPOND = "UPDATE_FILEPOND";

export const ADD_STEP_INSCRIPTION_RDP = "ADD_STEP_INSCRIPTION_RDP";
export const REMOVE_STEP_INSCRIPTION_RDP = "REMOVE_STEP_INSCRIPTION_RDP";

export const DISPLAY_OR_NOT_STEP_RECHERCHE_GLN = "DISPLAY_OR_NOT_STEP_RECHERCHE_GLN";

export const SELECT_PROFIL_ON_DIALOGN = "SELECT_PROFIL_ON_DIALOGN";
export const NEXT_STEP = "NEXT_STEP";
export const BACK_STEP = "BACK_STEP";
export const SET_ACTIVE_STEP = "SET_ACTIVE_STEP";
export const ADD_STEP_RECHERCHE_GLN_REFERENCEMENT = "ADD_STEP_RECHERCHE_GLN_REFERENCEMENT";

export const ADD_STEP_DEMANDE_REFERENCEMENT = "ADD_STEP_DEMANDE_REFERENCEMENT";
export const REMOVE_STEP_RECHERCHE_GLN_REFERENCEMENT = "REMOVE_STEP_RECHERCHE_GLN_REFERENCEMENT";

export const INIT_ACTIVE_STEP = "INIT_ACTIVE_STEP";
export const INIT_STATE_INSCRIPTION = "INIT_STATE_INSCRIPTION";
export const UPDATE_NUMERO_INSCRIPTION = "UPDATE_NUMERO_INSCRIPTION";
export const RECHERCHE_GLN_INSCRIPTION = "RECHERCHE_GLN_INSCRIPTION";
export const UPDATE_LOADING_INSCRIPTION = "UPDATE_LOADING_INSCRIPTION";
export const UPDATE_INSCRIPTION_IS_FORMULAIRE_STARTED = "UPDATE_INSCRIPTION_IS_FORMULAIRE_STARTED";

export const UPDATE_INSCRIPTION_IS_GENCOD_SELECTED = "UPDATE_INSCRIPTION_IS_GENCOD_SELECTED";
export const SAVE_INSCRIPTION = "SAVE_INSCRIPTION";

export const UPDATE_FORMULAIRE_REFERENCEMENT = "UPDATE_FORMULAIRE_REFERENCEMENT";

export const UPDATE_FORMULAIRE_RECHERCHE = "UPDATE_FORMULAIRE_RECHERCHE";

export const UPDATE_GENCOD_PARTENAIRE = "UPDATE_GENCOD_PARTENAIRE";
export const INIT_FORMULAIRE_REFERENCEMENT = "INIT_FORMULAIRE_REFERENCEMENT";
export const UPDATE_DEMANDE_REFERENCEMENT_NOT_SAVED = "UPDATE_DEMANDE_REFERENCEMENT_NOT_SAVED";
export const UPDATE_LOADING_FAVORIS_SELECTED = "UPDATE_LOADING_FAVORIS_SELECTED";

export const UPDATE_SHOW_SAVE_DEMANDE = "UPDATE_SHOW_SAVE_DEMANDE";
export const UPDATE_STATE_SAVE_DEMANDE_REFERENCEMENT = "UPDATE_STATE_SAVE_DEMANDE_REFERENCEMENT";
export const GLOBAL_DIALOG_OPEN = "GLOBAL_DIALOG_OPEN";

export const GLOBAL_DIALOG_OPEN_NOT_REDIRECT = "GLOBAL_DIALOG_OPEN_NOT_REDIRECT";
export const GLOBAL_DIALOG_CLOSE = "GLOBAL_DIALOG_CLOSE";
export const GLOBAL_DIALOG_CLOSE_SUCCESS = "GLOBAL_DIALOG_CLOSE_SUCCESS";


export const ADMIN_USURPER = "ADMIN_USURPER";
export const ADMIN_USURPER_START = "ADMIN_USURPER_START";
export const ADMIN_USURPER_SUCCESS = "ADMIN_USURPER_SUCCESS";
export const ADMIN_USURPER_FAIL = "ADMIN_USURPER_FAIL";
export const ADMIN_USURPER_CHECK_IF_FOUR_CAN_MAKE_GLOBAL_DECLARATION_SUCCESS = "ADMIN_USURPER_CHECK_IF_FOUR_CAN_MAKE_GLOBAL_DECLARATION_SUCCESS";
export const UPDATE_DISABLE_BUTTON = "UPDATE_DISABLE_BUTTON";
export const ADMIN_USURPER_UNAUTHORIZED = "ADMIN_USURPER_UNAUTHORIZED";

export const SEND_MAIL_FOR_PWD_RESET = "SEND_MAIL_FOR_PWD_RESET";
export const CHANGE_PWD_UNCONNECTED = "CHANGE_PWD_UNCONNECTED";
export const CHANGE_PWD_CONNECTED = "CHANGE_PWD_CONNECTED";
export const CHECK_TOKEN = "CHECK_TOKEN";

export const SAVE_DEMANDE_REFERENCEMENT = "SAVE_DEMANDE_REFERENCEMENT";
export const REDIRIGE_REFERENCEMENT = "REDIRIGE_REFERENCEMENT";
export const RECHERCHE_GLN_REFERENCEMENT = "RECHERCHE_GLN_REFERENCEMENT";

export const RECHERCHE_REFERENCEMENT_BY_GLN = "RECHERCHE_REFERENCEMENT_BY_GLN";
export const SELECT_TYPE_DECLARATION = "SELECT_TYPE_DECLARATION";
export const REDIRECT_TO_DECLARATION = "REDIRECT_TO_DECLARATION";
export const ADD_TO_FAVORIS_AND_REDIRECT_MES_PARTENAIRES = "ADD_TO_FAVORIS_AND_REDIRECT_MES_PARTENAIRES";
export const ADD_TO_FAVORIS_AND_REDIRECT_SAISIE_WEB = "ADD_TO_FAVORIS_AND_REDIRECT_SAISIE_WEB";
export const UTILISATEUR_GET_PARTENAIRES_FAVORIS = "UTILISATEUR_GET_PARTENAIRES_FAVORIS";
export const UTILISATEUR_GET_INFORMATIONS_DECLARANT = "UTILISATEUR_GET_INFORMATIONS_DECLARANT";
export const UTILISATEUR_GET_INFORMATIONS_DECLARANT_SUCCESS = "UTILISATEUR_GET_INFOS_SUCCESS";
export const UTILISATEUR_UPDATE_PREFERENCE_SIZE_PAGINATION = "UTILISATEUR_UPDATE_PREFERENCE_SIZE_PAGINATION";
export const UTILISATEUR_UPDATE_PREFERENCE_SIZE_PAGINATION_SUCCESS = "UTILISATEUR_UPDATE_PREFERENCE_SIZE_PAGINATION_SUCCESS";
export const UTILISATEUR_UPDATE_INFO_PREMIERE_CONNEXION_SUCCESS = "UTILISATEUR_UPDATE_INFO_PREMIERE_CONNEXION_SUCCESS";
export const UTILISATEUR_CHECK_IF_FOUR_CAN_MAKE_GLOBAL_DECLARATION = "UTILISATEUR_CHECK_IF_FOUR_CAN_MAKE_GLOBAL_DECLARATION";
export const UPDATE_FLAG_AND_MESSAGE_SNACK_BAR = "UPDATE_FLAG_AND_MESSAGE_SNACK_BAR";
export const UPDATE_LOCALE_FOR_USER = "UPDATE_LOCALE_FOR_USER";
export const UPDATE_GENCOD = "UPDATE_GENCOD";

export const UTILISATEUR_UPDATE_FLAG_BIBLIOTHEQUE_ETRANGERE = "UTILISATEUR_UPDATE_FLAG_BIBLIOTHEQUE_ETRANGERE";
export const UTILISATEUR_UPDATE_LOADING = "UTILISATEUR_UPDATE_LOADING";
export const RELOAD_FAVORIS_BEFORE_MOUNT = "RELOAD_FAVORIS_BEFORE_MOUNT";
export const UPDATE_NUMERO_TO_MAP = "UPDATE_NUMERO_TO_MAP";
export const UPDATE_MESSAGE_CONFIRMATION = "UPDATE_MESSAGE_CONFIRMATION";
export const UPDATE_ERROR_STYLE = "UPDATE_ERROR_STYLE";
export const UPDATE_URL_AIDE_UTILISATEUR = "UPDATE_URL_AIDE_UTILISATEUR";
export const UPDATE_IS_CALLED_INSCRIPTION = "UPDATE_IS_CALLED_INSCRIPTION";
export const UPDATE_STATUT_SAVE_INSCRIPTION = "UPDATE_STATUT_SAVE_INSCRIPTION";
export const UPDATE_FORMULAIRE_MAIL_VALIDE = "UPDATE_FORMULAIRE_MAIL_VALIDE";
export const SEND_MESSAGE_NOUS_CONTACTER = "SEND_MESSAGE_NOUS_CONTACTER";

// Notifications
export const GET_ALL_NOTIFICATIONS = "GET_ALL_NOTIFICATIONS";
export const INCREMENT_NB_NOTIFICATIONS = "INCREMENT_NB_NOTIFICATIONS";
export const DECREMENT_NB_NOTIFICATIONS = "DECREMENT_NB_NOTIFICATIONS";
export const UPDATE_NOTIFICATIONS_SUCCESS = "UPDATE_NOTIFICATIONS_SUCCESS";
export const UPDATE_ECARTS_DETECTE = "UPDATE_ECARTS_DETECTE";
export const UPDATE_VUE_NOTIFICATIONS = "UPDATE_VUE_NOTIFICATIONS";

export const UPDATE_NOTIFICATIONS_MAIL = "UPDATE_NOTIFICATIONS_MAIL";
export const UPDATE_NOTIFICATIONS_LOADING = "UPDATE_NOTIFICATIONS_LOADING";


// Déclaration
export const UPDATE_INFO_PARTENAIRE = "UPDATE_INFO_PARTENAIRE";
export const UPDATE_FLAG_SEARCH_INFO_PARTENAIRE = "UPDATE_FLAG_SEARCH_INFO_PARTENAIRE";
export const SEARCH_INFO_PARTENAIRE = "SEARCH_INFO_PARTENAIRE";
export const UPDATE_ACCORDION_ITEM_ARRAY = "UPDATE_ACCORDION_ITEM_ARRAY";
export const SEND_DECLARATION_SAGA = "SEND_DECLARATION_SAGA";
export const SENT_DECLARATION = "SENT_DECLARATION";
export const UPDATE_DECLARATION_STORE = "UPDATE_DECLARATION_STORE";
export const UPDATE_DECLARATION_ENTETE = "UPDATE_DECLARATION_ENTETE";
export const UPDATE_DECLARATION_ENTETE_AND_SAVE_SAGA = "UPDATE_DECLARATION_ENTETE_AND_SAVE_SAGA";
export const REVALID_DECLARATION_ENTETES_SAGA = "REVALID_DECLARATION_ENTETES_SAGA";
export const CANCEL_DECLARATION_ENTETES_SAGA = "CANCEL_DECLARATION_ENTETES_SAGA";
export const CANCEL_DECLARATION_WEB_ENTETES_SAGA = "CANCEL_DECLARATION_WEB_ENTETES_SAGA";
export const SAVE_DECLARATION = "SAVE_DECLARATION";
export const UPDATE_DECLARATION_LIGNES = "UPDATE_DECLARATION_LIGNES";
export const UPDATE_DECLARATION_LIGNES_AND_ORDER = "UPDATE_DECLARATION_LIGNES_AND_ORDER";
export const UPDATE_DECLARATION_LIGNES_AND_ENTETES_AND_CODE_POSTAL_FOURNISSEUR = "UPDATE_DECLARATION_LIGNES_AND_ENTETES_AND_CODE_POSTAL_FOURNISSEUR";
export const UPDATE_DECLARATION_ALL_ENTETES = "UPDATE_DECLARATION_ALL_ENTETES";

export const UPDATE_DECLARATION_CODE_POSTAL_FOURNISSEUR = "UPDATE_DECLARATION_CODE_POSTAL_FOURNISSEUR";
export const UPDATE_DECLARATION_AND_SAVE_ALL_ENTETES = "UPDATE_DECLARATION_AND_SAVE_ALL_ENTETES";
export const UPDATE_DECLARATION_LIGNE = "UPDATE_DECLARATION_LIGNE";
export const UPDATE_DECLARATION_LIGNES_SAGA = "UPDATE_DECLARATION_LIGNES_SAGA";
export const REVALID_DECLARATION_LIGNE = "REVALID_DECLARATION_LIGNE";
export const REVALID_DECLARATION_LIGNE_SAGA = "REVALID_DECLARATION_LIGNE_SAGA";
export const DELETE_DECLARATION_LIGNE = "DELETE_DECLARATION_LIGNE";
export const DELETE_DECLARATION_LIGNE_SAGA = "DELETE_DECLARATION_LIGNE_SAGA";
export const CANCEL_DECLARATION_LIGNE = "CANCEL_DECLARATION_LIGNE";
export const CANCEL_DECLARATION_LIGNE_SAGA = "CANCEL_DECLARATION_LIGNE_SAGA";
export const MERGE_LIGNE = "MERGE_LIGNE";
export const MERGE_LIGNE_SAGA = "MERGE_LIGNE_SAGA";
export const REINITIALIZE_DECLARATION_DATA = "REINITIALIZE_DECLARATION_DATA";
export const SAVE_DECLARATIONS_SUCCESS = "SAVE_DECLARATIONS_SUCCESS";
export const UPDATE_DECLARATION_REQUEST_INDICATOR = "UPDATE_DECLARATION_REQUEST_INDICATOR";
export const UPDATE_DECLARANT_INFOS = "UPDATE_DECLARANT_INFOS";
export const UPDATE_DECLARATION_ENTETE_LIST = "UPDATE_DECLARATION_ENTETE_LIST";
export const UPDATE_ENABLE_UPDATE_STORE = "UPDATE_ENABLE_UPDATE_STORE";
export const REINIT_PARTENAIRE = "REINIT_PARTENAIRE";
export const UPDATE_FILTRE_SEARCH_GLN = "UPDATE_FILTRE_SEARCH_GLN";
export const SET_DECLARATION_LOADING = "SET_DECLARATION_LOADING";
export const VALIDATE_INTEGRATION_EN_ETAT = "VALIDATE_DECLARATIONS_EN_ETAT";
export const UPDATE_DECLARATION_READ_ONLY = "UPDATE_DECLARATION_READ_ONLY";
export const REINIT_DECLARATION_TIME = "REINIT_DECLARATION_TIME";
export const UPDATE_DECLARATION_GLOBALE = "UPDATE_DECLARATION_GLOBALE";
export const SET_TVA_FOR_ENTETE = "SET_TVA_FOR_ENTETE";
export const SET_TVA_FOR_ENTETE_RESULT = "SET_TVA_FOR_ENTETE_RESULT";
export const UPDATE_DISABLE_SEND_BUTTON = "UPDATE_DISABLE_SEND_BUTTON";
export const UPDATE_DISABLE_SEND_BUTTON_LIGNES = "UPDATE_DISABLE_SEND_BUTTON_LIGNES";
export const INIT_RECHERCHE_GLN = "INIT_RECHERCHE_GLN";

// Integration Tableur
export const GET_INTEGRATION_RESULT_FAILED = "GET_INTEGRATION_RESULT_FAILED";
export const GET_INTEGRATION_RESULT_SUCCESS = "GET_INTEGRATION_RESULT_SUCCESS";
export const GET_INTEGRATION = "GET_INTEGRATION";
export const REDIRECT_TO_INTEGRATION_FROM_DECLARATION = "REDIRECT_TO_INTEGRATION_FROM_DECLARATION";
export const SET_INTEGRATION_STATUT = "SET_INTEGRATION_STATUT";
export const REINIT_INTEGRATION_INFO = "REINIT_INTEGRATION_INFO";
export const DOWNLOAD_MODELE_EXCEL = "DOWNLOAD_MODELE_EXCEL";
export const DOWNLOAD_RECAPITULATIF_INTEGRATION = "DOWNLOAD_RECAPITULATIF_INTEGRATION";
export const UPDATE_DELETE_INTEGRATION_LOADING = "UPDATE_DELETE_INTEGRATION_LOADING";

//  Integration tableur - Récapitulatif
export const INTEGRATION_DECLARATION_TABLEUR = "INTEGRATION_DECLARATION_TABLEUR";
export const GET_INTEGRATIONS_TABLEUR_BY_GLN_DECLARANT = "GET_INTEGRATIONS_TABLEUR_BY_GLN_DECLARANT";
export const GET_INTEGRATIONS_TABLEUR_BY_GLN_DECLARANT_RESULTS = "GET_INTEGRATIONS_TABLEUR_BY_GLN_DECLARANT_RESULTS";
export const DELETE_INTEGRATION_TABLEUR = "DELETE_INTEGRATION_TABLEUR";
export const SET_INTEGRATION_TABLEUR_RECAPITULATIF_LOADING = "SET_INTEGRATION_TABLEUR_RECAPITULATIF_LOADING";
export const DISPLAY_ERROR_MESSAGE = "DISPLAY_ERROR_MESSAGE";

//  Integration tableur - Choix des entêtes
export const GET_ECHANTILLON_ENTETE_IDENTIFIEES_ET_ATTENDUES_BY_ID_INTEGRATION = "GET_ECHANTILLON_ENTETE_IDENTIFIEES_ET_ATTENDUES_BY_ID_INTEGRATION";
export const GET_ECHANTILLON_ENTETE_IDENTIFIEES_ET_ATTENDUES_BY_ID_INTEGRATION_RESULT = "GET_ECHANTILLON_ENTETE_IDENTIFIEES_ET_ATTENDUES_BY_ID_INTEGRATION_RESULT";
export const SET_INTEGRATION_TABLEUR_ENTETE_LOADING = "SET_INTEGRATION_TABLEUR_ENTETE_LOADING";
export const UPDATE_CONFIRM_ENTETE_LOADING = "UPDATE_CONFIRM_ENTETE_LOADING";
export const SET_SENT_INTEGRATION_LOADING = "SET_SENT_INTEGRATION_LOADING";
export const CONFIRM_CHOIX_ENTETE = "CONFIRM_CHOIX_ENTETE";

// Integration tableur - Correction des erreurs
export const SET_INTEGRATION_TABLEUR_CORRECTION_ERREUR_LOADING = "SET_INTEGRATION_TABLEUR_CORRECTION_ERREUR_LOADING";
export const SET_INTEGRATION_TABLEUR_CORRECTION_ERREUR_LOADING_PERCENTAGE = "SET_INTEGRATION_TABLEUR_CORRECTION_ERREUR_LOADING_PERCENTAGE";
export const GET_INTEGRATION_TABLEUR_CORRECTION_ERREUR_LOADING_PERCENTAGE_SAGA = "GET_INTEGRATION_TABLEUR_CORRECTION_ERREUR_LOADING_PERCENTAGE_SAGA";
export const GET_INTEGRATION_TABLEUR_CORRECTION_ERREUR_LIGNES_BY_ID_INTEGRATION = "GET_INTEGRATION_TABLEUR_CORRECTION_ERREUR_LIGNES_BY_ID_INTEGRATION";
export const GET_INTEGRATION_TABLEUR_CORRECTION_ERREUR_LIGNES_BY_ID_INTEGRATION_RESULT = "GET_INTEGRATION_TABLEUR_CORRECTION_ERREUR_LIGNES_BY_ID_INTEGRATION_RESULT";
export const GET_INTEGRATION_TABLEUR_CORRECTION_ERREUR_ENTETES_CHOISIES_BY_ID_INTEGRATION = "GET_INTEGRATION_TABLEUR_CORRECTION_ERREUR_ENTETES_CHOISIES_BY_ID_INTEGRATION";
export const GET_INTEGRATION_TABLEUR_CORRECTION_ERREUR_ENTETES_CHOISIES_BY_ID_INTEGRATION_RESULT = "GET_INTEGRATION_TABLEUR_CORRECTION_ERREUR_ENTETES_CHOISIES_BY_ID_INTEGRATION_RESULT";
export const SAVE_GET_INTEGRATION_TABLEUR_CORRECTION_ERREUR_LIGNES = "SAVE_GET_INTEGRATION_TABLEUR_CORRECTION_ERREUR_LIGNES";
export const SAVE_GET_INTEGRATION_TABLEUR_CORRECTION_ERREUR_LIGNES_RESULT = "SAVE_GET_INTEGRATION_TABLEUR_CORRECTION_ERREUR_LIGNES_RESULT";
export const DELETE_GET_INTEGRATION_TABLEUR_CORRECTION_ERREUR_STATS = "DELETE_GET_INTEGRATION_TABLEUR_CORRECTION_ERREUR_STATS";
export const DELETE_GET_INTEGRATION_TABLEUR_CORRECTION_ERREUR_STATS_RESULT = "DELETE_GET_INTEGRATION_TABLEUR_CORRECTION_ERREUR_STATS_RESULT";
export const SET_INTEGRATION_TABLEUR_CORRECTION_ERREUR_SAUVEGARDE_LOADING = "SET_INTEGRATION_TABLEUR_CORRECTION_ERREUR_SAUVEGARDE_LOADING";
export const CREATE_DECLARATIONS_FROM_FICHIER = "CREATE_DECLARATIONS_FROM_FICHIER";
export const OPEN_OR_CLORE_BAR_PROGRESS_DIALOG = "OPEN_OR_CLORE_BAR_PROGRESS_DIALOG";

// Integration tableur - Déclaration
export const GET_LIST_DECLARATIONS_ENTETE_BY_INTEGRATION = "GET_LIST_DECLARATIONS_ENTETE_BY_INTEGRATION";
export const GET_LIST_DECLARATIONS_ENTETE_BY_INTEGRATION_RESULT = "GET_LIST_DECLARATIONS_ENTETE_BY_INTEGRATION_RESULT";
export const GET_LIST_DECLARATIONS_LIGNE_BY_NUMERO_DECLARATION = "GET_LIST_DECLARATIONS_LIGNE_BY_NUMERO_DECLARATION";
export const GET_LIST_DECLARATIONS_LIGNE_BY_NUMERO_DECLARATION_RESULT = "GET_LIST_DECLARATIONS_LIGNE_BY_NUMERO_DECLARATION_RESULT";
export const VALIDATE_ONE_DECLARATION_EN_ETAT_INTEGRATION_EXCEL = "VALIDATE_ONE_DECLARATION_EN_ETAT_INTEGRATION_EXCEL";
export const CLOSE_DECLARATION = "CLOSE_DECLARATION";
export const REINIT_INTEGRATION_RECAP_DECLARATION = "REINIT_INTEGRATION_RECAP_DECLARATION";
export const UPDATE_DECLARATION_ENTETE_STATUS = "UPDATE_DECLARATION_ENTETE_STATUS";
export const UPDATE_DECLARATION_ENTETE_STATUS_SUCCESS = "UPDATE_DECLARATION_ENTETE_STATUS_SUCCESS";
export const UPDATE_DECLARATION_ENTETE_ET_LIGNES = "UPDATE_DECLARATION_ENTETE_ET_LIGNES";
export const UPDATE_ROW_INDEX_INTEGRATION = "UPDATE_ROW_INDEX_INTEGRATION";
export const UPDATE_IE_OPEN_DETAIL_DIALOG = "UPDATE_IE_OPEN_DETAIL_DIALOG";
export const REINIT_DATA_CORRECTION_ERREUR = "REINIT_DATA_CORRECTION_ERREUR";
export const PASS_TO_STEP_CORRECTION_ERREUR = "PASS_TO_STEP_CORRECTION_ERREUR";

// Mes Declarations
export const UPDATE_MD_SORT_COLUMN = "UPDATE_MD_SORT_COLUMN";
export const UPDATE_MD_FILTRE_TYPE_ANNEE = "UPDATE_MD_FILTRE_TYPE_ANNEE";
export const UPDATE_MD_PAGE = "UPDATE_PAGE";
export const UPDATE_MD_TYPE_DECLARATION = "UPDATE_TYPE_DECLARATION";
export const REINIT_MD_TYPE_DECLARATION = "REINIT_MD_TYPE_DECLARATION";
export const UPDATE_MD_GLN_DECLARANT = "UPDATE_GLN_DECLARANT";
export const UPDATE_MD_GLN_DECLARANT_AND_REINIT_PARTENAIRE = "UPDATE_MD_GLN_DECLARANT_AND_REINIT_PARTENAIRE";
export const UPDATE_MD_PARTENAIRES = "UPDATE_MD_PARTENAIRES";
export const UPDATE_MD_FILTRE_PARTENAIRES = "UPDATE_MD_FILTRE_PARTENAIRES";
export const UPDATE_MD_ANNEE_DEBUT = "UPDATE_MD_ANNEE_DEBUT";
export const UPDATE_MD_ANNEE_FIN = "UPDATE_MD_ANNEE_FIN";
export const UPDATE_MD_PERIOD = "UPDATE_MD_PERIOD";
export const UPDATE_MD_TYPE_ANNEE = "UPDATE_TYPE_ANNEE";
export const UPDATE_MD_SHOW_ONLY_INVALIDE_DECLARATION = "UPDATE_MD_SHOW_ONLY_INVALIDE_DECLARATION";
export const UPDATE_MD_DOUTES = "UPDATE_DOUTES";
export const UPDATE_MD_NOMBRE_DECLARATION_PAR_TYPE = "UPDATE_MD_NOMBRE_DECLARATION_PAR_TYPE";
export const GET_INFOS_NECESSAIRES = "GET_INFOS_NECESSAIRES";
export const UPDATE_MD_ALL_INFOS = "UPDATE_ALL_INFOS";
export const UPDATE_MD_RESULTAT = "UPDATE_RESULTAT";
export const UPDATE_MD_RESULTAT_SAGA = "UPDATE_MD_RESULTAT_SAGA";
export const GET_MD_LIST_DECLARATIONS_LIGNE_BY_NUMERO_DECLARATION = "GET_MD_LIST_DECLARATIONS_LIGNE_BY_NUMERO_DECLARATION";
export const GET_MD_LIST_DECLARATIONS_LIGNE_BY_NUMERO_DECLARATION_RESULT = "GET_MD_LIST_DECLARATIONS_LIGNE_BY_NUMERO_DECLARATION_RESULT";
export const CLEAR_MD_STORE = "CLEAR_MD_STORE";
export const UPDATE_MD_INDEX_OPENED_DECLARATION = "UPDATE_MD_INDEX_OPENED_DECLARATION";
export const SEND_MD_ALL_FILTERED = "SEND_MD_ALL_FILTERED";
export const UPDATE_MD_TOTAL_SIZE_RECAP = "UPDATE_MD_TOTAL_SIZE_RECAP";
export const INVALIDATE_DECLARATION_BY_NUMERO = "INVALIDATE_DECLARATION_BY_NUMERO";

export const ROLLBACK_DECLARATION_MODIFICATION_BY_NUMERO = "ROLLBACK_DECLARATION_MODIFICATION_BY_NUMERO";
export const SEND_DECLARATION_BY_NUMERO = "SEND_DECLARATION_BY_NUMERO";
export const UPDATE_MD_SOURCE_DECLARATION = "UPDATE_MD_SOURCE_DECLARATION";
export const UPDATE_MD_DECLARATION_ENTETE_ET_LIGNES = "UPDATE_MD_DECLARATION_ENTETE_ET_LIGNES";
export const UPDATE_MD_DIALOG_DECLARATION_MODIF_EN_COURS = "UPDATE_MD_DIALOG_DECLARATION_MODIF_EN_COURS";
export const CLOSE_MD_DECLARATION = "CLOSE_MD_DECLARATION";
export const UPDATE_DECLARATION_ID_WRAPPER_INFO = "UPDATE_DECLARATION_ID_WRAPPER_INFO";
export const UPDATE_ENTETES_AND_ROWINDEX = "GET_ENTETES_AND_SET_ROWINDEX";
export const UPDATE_ENTETES_AND_ROWINDEX_RESULT = "UPDATE_ENTETES_AND_ROWINDEX";
export const RELOAD_TYPE_DECLARATION = "RELOAD_TYPE_DECLARATION";
export const MODIFY_DECLARATION_FACTUREE = "MODIFY_DECLARATION_FACTUREE";
export const CHECK_STATUT_MD_DECLARATION_SAGA = "CHECK_STATUT_MD_DECLARATION_SAGA";
export const ON_NEXT_DECLARATION_FROM_MES_DECLARATIONS_SAGA = "ON_NEXT_DECLARATION_FROM_MES_DECLARATIONS_SAGA";
export const ON_PREVIOUS_DECLARATION_FROM_MES_DECLARATIONS_SAGA = "ON_PREVIOUS_DECLARATION_FROM_MES_DECLARATIONS_SAGA";
export const CLOSE_DETAIL_DIALOG_FROM_MES_DECLARATIONS_SAGA = "CLOSE_DETAIL_DIALOG_FROM_MES_DECLARATIONS_SAGA";
export const ADD_MD_CLONE = "ADD_MD_CLONE";
export const UPDATE_MD_SHOW_CLONE = "UPDATE_MD_SHOW_CLONE";
export const UPDATE_MD_SHOW_ORIGINALE = "UPDATE_MD_SHOW_ORIGINALE";
export const UPDATE_MD_OPEN_DETAIL_DIALOG = "UPDATE_MD_OPEN_DETAIL_DIALOG";
export const VALIDATE_ONE_DECLARATION_EN_ETAT_MES_DECLARATIONS = "VALIDATE_ONE_DECLARATION_EN_ETAT_MES_DECLARATIONS";
export const SET_MD_LOADING = "SET_MD_LOADING";
export const GET_MD_PARTENAIRE_AND_UPDATE_FILTRE = "GET_MD_PARTENAIRE_AND_UPDATE_FILTRE";
export const UPDATE_MD_STATISTIQUES_MONTANTS = "UPDATE_MD_STATISTIQUES_MONTANTS";
export const UPDATE_MD_STATISTIQUES_MONTANTS_RESULT = "UPDATE_MD_STATISTIQUES_MONTANTS_RESULT";
export const SET_MD_LOADING_STATISTIQUES_MONTANTS = "SET_MD_LOADING_STATISTIQUES_MONTANTS";
export const SET_MD_SHOW_STATISTIQUES_MONTANTS = "SET_MD_SHOW_STATISTIQUES_MONTANTS";
export const UPDATE_MD_FILTRE_NUMERO_FACTURE = "UPDATE_MD_FILTRE_NUMERO_FACTURE";

export const REFRESH_MD_STORE = "REFRESH_MD_STORE";

// Statistique Déclarations
export const EXPORT_MENSUALISATION = "EXPORT_MENSUALISATION";
export const EXPORT_LIGNES_EAN = "EXPORT_LIGNES_EAN";
export const LOAD_PARTENAIRES_AND_FACTURES = "LOAD_PARTENAIRES_AND_FACTURES";
export const LOAD_FACTURES = "LOAD_FACTURES";
export const UPDATE_STATISTIQUES_MENSUELLES_ANNEE_DEBUT = "UPDATE_STATISTIQUES_MENSUELLES_ANNEE_DEBUT";
export const UPDATE_STATISTIQUES_MENSUELLES_ANNEE_FIN = "UPDATE_STATISTIQUES_MENSUELLES_ANNEE_FIN";
export const UPDATE_ANNEE_CIVILE = "UPDATE_ANNEE_CIVILE";
export const UPDATE_FILTRES_PARTENAIRES_FACTURES = "UPDATE_FILTRES_PARTENAIRES_FACTURES";
export const UPDATE_SELECTED_PARTENAIRES = "UPDATE_SELECTED_PARTENAIRES";
export const UPDATE_LOADING_STATITSTIQUES_PARTENAIRES_FACTURES = "UPDATE_LOADING_STATITSTIQUES_PARTENAIRES_FACTURES";
export const REINIT_STATISTIQUES_VIEW = "REINIT_STATISTIQUES_VIEW";
export const EXPORT_RECAPITULATIF_VENTE = "EXPORT_RECAPITULATIF_VENTE";
export const UPDATE_FILTRES_FACTURES = "UPDATE_FILTRES_FACTURES";
export const UPDATE_SELECTED_FACTURES = "UPDATE_SELECTED_FACTURES";
export const UPDATE_LOADING_STATITSTIQUES_FACTURES = "UPDATE_LOADING_STATITSTIQUES_FACTURES";
export const UPDATE_DISABLE_PARTENAIRE_FILTRE = "UPDATE_DISABLE_PARTENAIRE_FILTRE";
export const UPDATE_STORE_AND_GET_PARTENAIRES_FACTURES = "UPDATE_STORE_AND_GET_PARTENAIRES_FACTURES";
export const REINIT_ALL_FILTRE = "REINIT_ALL_FILTRE";
export const UPDATE_DISABLE_FACTURE_FILTRE = "UPDATE_DISABLE_FACTURE_FILTRE";
export const EXPORT_EAN_GET_COUNT_LIGNE = "EXPORT_EAN_GET_COUNT_LIGNE";
export const EXPORT_EAN_UPDATE_COUNT_LIGNE = "EXPORT_EAN_UPDATE_COUNT_LIGNE";
export const EXPORT_EAN_UPDATE_LOADING_COUNT_LIGNE = "EXPORT_EAN_UPDATE_LOADING_COUNT_LIGNE";

export const UPDATE_LISTE_ECARTS_GLOBAL = "UPDATE_LISTE_ECARTS_GLOBAL";
export const CHECK_IF_ECART_IS_PRESENT = "CHECK_IF_ECART_IS_PRESENT";
export const UPDATE_LISTE_ECARTS_DETAILLE = "UPDATE_LISTE_ECARTS_DETAILLE";
export const UPDATE_TOTAL_ECARTS_DETAILLE = "UPDATE_TOTAL_ECARTS_DETAILLE";
export const UPDATE_LOADING = "UPDATE_LOADING";
export const UPDATE_PAGE = "UPDATE_PAGE";
export const OPEN_CROISEMENT_DIALOG = "OPEN_CROISEMENT_DIALOG";
export const OPEN_CROISEMENT_DIALOG_SAGA = "OPEN_CROISEMENT_DIALOG_SAGA";
export const CLOSE_CROISEMENT_DIALOG = "CLOSE_CROISEMENT_DIALOG";
export const UPDATE_SORT_COLUMN = "UPDATE_SORT_COLUMN";
export const GET_LISTE_ECARTS_FOUR_BIB = "GET_LISTE_ECARTS_FOUR_BIB";
export const GET_COUNT_ECART_FOUR_BIB = "GET_COUNT_ECART_FOUR_BIB";

// Attestation de fin de déclarations
export const ATTESTATION_FIN_GET_ATTESTATIONS = "ATTESTATION_FIN_GET_ATTESTATIONS";
export const ATTESTATION_FIN_GET_ATTESTATIONS_SUCCESS = "ATTESTATION_FIN_GET_ATTESTATIONS_SUCCESS";
export const ATTESTATION_FIN_LOADING = "ATTESTATION_FIN_LOADING";
export const ATTESTATION_FIN_ANNEE_LOADING = "ATTESTATION_FIN_ANNEE_LOADING";
export const ATTESTATION_FIN_DOWNLOAD_ATTESTATION = "ATTESTATION_FIN_DOWNLOAD_ATTESTATION";
export const ATTESTATION_FIN_SEND_ATTESTATION = "ATTESTATION_FIN_UPDATE_CONTACT";


// Mes Factures
export const UPDATE_MES_FACTURES_ETAT_REGLEMENT_FILTRE = "UPDATE_MES_FACTURES_ETAT_REGLEMENT_FILTRE";
export const UPDATE_MES_FACTURES_ANNEE_FILTRE = "UPDATE_MES_FACTURES_ANNEE_FILTRE";
export const GET_MES_FACTURES = "GET_MES_FACTURES";
export const GET_MES_FACTURES_SUCCESS = "GET_MES_FACTURES_SUCCESS";
export const UPDATE_LOADING_MES_FACTURES = "UPDATE_LOADING";
export const UPDATE_SORT_COLUMN_MES_FACTURES = "UPDATE_SORT_COLUMN_MES_FACTURES";
export const UPDATE_TOTAL_MES_FACTURES = "UPDATE_TOTAL_MES_FACTURES";
export const UPDATE_PAGE_MES_FACTURES = "UPDATE_PAGE_MES_FACTURES";
export const DOWNLOAD_FACTURE = "DOWNLOAD_FACTURE";

export const DOWNLOAD_FACTURE_FROM_DECLARATION = "DOWNLOAD_FACTURE_FROM_DECLARATION";
export const GET_COUNT_MES_NOUVELLES_FACTURES = "GET_COUNT_MES_NOUVELLES_FACTURES";
export const UPDATE_COUNT_MES_NOUVELLES_FACTURES = "UPDATE_COUNT_MES_NOUVELLES_FACTURES";
export const GET_ALL_FACTURES_NOTIFICATIONS = "GET_ALL_FACTURES_NOTIFICATIONS";
export const REINIT_FACTURES_FILTRE = "REINIT_FACTURES_FILTRE";
export const UPDATE_FACTURE_LOADING = "UPDATE_FACTURE_LOADING";


// Mon profil
export const UPDATE_LOADING_MON_PROFIL = "UPDATE_LOADING_MON_PROFIL";
export const GET_MON_PROFIL = "GET_MON_PROFIL_DATAS";
export const GET_MON_PROFIL_AVEC_NOTIFICATION = "GET_MON_PROFIL_AVEC_NOTIFICATION";
export const GET_MON_PROFIL_SUCCESS = "GET_MON_PROFIL_DATAS_SUCCESS";
export const SAVE_MON_PROFIL_COMPTE_UTILISATEUR = "SAVE_MON_PROFIL_COMPTE_UTILISATEUR";
export const SAVE_MON_PROFIL_COMPTE_UTILISATEUR_SUCCESS = "SAVE_MON_PROFIL_COMPTE_UTILISATEUR_SUCCESS";
export const SAVE_MON_PROFIL_CONTACT_GLOBAL = "SAVE_MON_PROFIL_CONTACT_GLOBAL";
export const SAVE_MON_PROFIL_CONTACT_SUCCESS = "SAVE_MON_PROFIL_INFO_CONTACT_SUCCESS";
export const SAVE_MON_PROFIL_CONTACT_AND_DUPLICATE_IT = "SAVE_MON_PROFIL_CONTACT_AND_DUPLICATE_IT";
export const SAVE_MON_PROFIL_CONTACTS_SUCCESS = "SAVE_MON_PROFIL_CONTACTS_SUCCESS";
export const CHECK_MAIL_TOKEN = "CHECK_MAIL_TOKEN";
export const CHECK_MAIL_TOKEN_SUCCESS = "CHECK_MAIL_TOKEN_SUCCESS";
export const UPDATE_VUE_CROISEMENT = "UPDATE_VUE_CROISEMENT";
export const UPDATE_VUE_CROISEMENT_SUCCESS = "UPDATE_VUE_CROISEMENT_SUCCESS";
export const GO_TO_NEXT_STEP_PREMIERE_CONNEXION = "GO_TO_NEXT_STEP_PREMIERE_CONNEXION";
export const GO_TO_PREVIOUS_STEP_PREMIERE_CONNEXION = "GO_TO_PREVIOUS_STEP_PREMIERE_CONNEXION";
export const UPDATE_SKIP_STEP_PREMIERE_CONNEXION = "UPDATE_SKIP_STEP_PREMIERE_CONNEXION";
export const SET_STEP_FORCAGE_PREMIERE_CO = "SET_STEP_FORCAGE_PREMIERE_CO";
export const SET_LAST_VALIDATED_STEP_PREMIERE_CO = "SET_LAST_VALIDATED_STEP_PREMIERE_CO";
export const SET_STEPS_FORCAGE_PREMIERE_CO = "SET_STEPS_FORCAGE_PREMIERE_CO";
export const UPDATE_MON_PROFIL_PREMIERE_CONNEXION_INFOS = "UPDATE_MON_PROFIL_PREMIERE_CONNEXION_INFOS";
export const SAVE_MON_PROFIL_CONTACT = "SAVE_MON_PROFIL_CONTACT";

export const UPDATE_MON_PROFIL_CONTACT_MAIL = "UPDATE_MON_PROFIL_CONTACT_MAIL";
export const UPDATE_MON_PROFIL_CONTACT_MAIL_SUCESS = "UPDATE_MON_PROFIL_CONTACT_MAIL_SUCESS";
export const UPDATE_MON_PROFIL_SHOW_CONFIRMATION_MAIL = "UPDATE_MON_PROFIL_SHOW_EDIT_MAIL";
export const UPDATE_MON_PROFIL_DATA_NOT_SAVED = "UPDATE_MON_PROFIL_DATA_NOT_SAVED";
export const UPDATE_MON_PROFIL_LAST_STEP_FIRST_CO_FOURNI = "UPDATE_MON_PROFIL_LAST_STEP_FIRST_CO_FOURNI";
export const UPDATE_MON_PROFIL_MAIL = "UPDATE_MON_PROFIL_MAIL";
export const UPDATE_MON_PROFIL_MAIL_CONFIRMATION = "UPDATE_MON_PROFIL_MAIL_CONFIRMATION";
export const UPDATE_MON_PROFIL_MAIL_VALIDE = "UPDATE_MON_PROFIL_MAIL_VALIDE";
export const UPDATE_TOKEN_RESET_MDP_CHECKED = "UPDATE_TOKEN_RESET_MDP_CHECKED";

// Libelle
export const GET_LIBELLES = "GET_LIBELLES";
export const GET_LIBELLES_SUCCESS = "GET_LIBELLES_SUCCESS";

// Mes partenaires
export const UPDATE_LOADING_MES_PARTENAIRES = "UPDATE_LOADING_MES_PARTENAIRES";
export const UPDATE_LOADING_MES_PARTENAIRES_FAVORIS = "UPDATE_LOADING_MES_PARTENAIRES_FAVORIS";
export const UPDATE_LOADING_MES_PARTENAIRES_RECENTS = "UPDATE_LOADING_MES_PARTENAIRES_RECENTS";
export const GET_MES_PARTENAIRES = "GET_MES_PARTENAIRES";
export const GET_MES_PARTENAIRES_FAVORIS = "GET_MES_PARTENAIRES_FAVORIS";
export const GET_MES_PARTENAIRES_FAVORIS_SUCCESS = "GET_MES_PARTENAIRES_FAVORIS_SUCCESS";
export const GET_MES_PARTENAIRES_RECENTS = "GET_MES_PARTENAIRES_RECENTS";
export const GET_MES_PARTENAIRES_RECENTS_SUCCESS = "GET_MES_PARTENAIRES_RECENTS_SUCCESS";
export const ADD_PARTENAIRE_TO_FAVORIS = "ADD_PARTENAIRE_TO_FAVORIS";
export const REMOVE_PARTENAIRE_FROM_FAVORIS = "REMOVE_PARTENAIRE_FROM_FAVORIS";
export const EXPOTER_PARTENAIRES = "EXPOTER_PARTENAIRES";
export const UPDATE_LOADING_MES_PARTENAIRES_EXPORT = "UPDATE_LOADING_MES_PARTENAIRES_EXPORT";
export const UPDATE_SORT_MES_PARTENAIRES_FAVORIS = "UPDATE_SORT_MES_PARTENAIRES_FAVORIS";
export const UPDATE_SORT_MES_PARTENAIRES_RECENTS = "UPDATE_SORT_MES_PARTENAIRES_RECENTS";
export const UPDATE_PAGE_MES_PARTENAIRES_FAVORIS = "UPDATE_PAGE_MES_PARTENAIRES_FAVORIS";
export const UPDATE_PAGE_MES_PARTENAIRES_RECENTS = "UPDATE_PAGE_MES_PARTENAIRES_RECENTS";
export const UPDATE_PAGE_SIZE_MES_PARTENAIRES_FAVORIS = "UPDATE_PAGE_SIZE_MES_PARTENAIRES_FAVORIS";
export const UPDATE_PAGE_SIZE_MES_PARTENAIRES_RECENTS = "UPDATE_PAGE_SIZE_MES_PARTENAIRES_RECENTS";
export const UPDATE_SHOW_DELETE_FAVORI_POPUP = "UPDATE_SHOW_DELETE_FAVORI_POPUP";
export const CHECK_IF_PARTENAIRE_RECENT = "CHECK_IF_PARTENAIRE_RECENT";


