import { updateObject } from "../../../utils/utility";
import * as actionTypes from "../../actionTypes/actionTypes";
import { MON_PROFIL_STEP_RDP } from "../../../components/monProfil/monProfilUtil";

const initialState = {
    loading: false,
    isCorrectPwd: false,
    isMailChecked: "",
    tokenResetMdpChecked: "",
    compteUtilisateur: {
        gencod: "",
        raisonSociale1: "",
        raisonSociale2: "",
        numeroVoie: "",
        preAdresse: "",
        adresse1: "",
        adresse2: "",
        codePostal: "",
        ville: "",
        codePays: "",
        siret: "",
        ape: "",
        formeJuridique: "",
        formeJuridiqueAutre: "",
        dateCreation: null,
        telephone: "",
        courriel: "",
        site: "",
        ediSsii: "",
        siegeSocial: false,
        typeAutre: ""
    },
    contacts: [],
    infoSofia: {
        assujettissementDDP: null,
        regroupe: false,
        entiteGerante: "",
        gestionnaire: false,
        contentieux: false,
        npai: null,
        compteClos: null,
        deltaDetaille: false
    },
    dataNotSaved: false,
    currentStepPremiereCo: "",
    lastValidatedStepPremiereCo: "",
    skipStep: false,
    steps: []
};

function getMonProfilSuccess(state, datas) {
    const { compteUtilisateur, contacts, infoSofia } = datas;
    return updateObject(state, {
        compteUtilisateur,
        contacts,
        infoSofia
    });
}

function updateContact(actualContacts, newContact) {
    const indexContact = actualContacts.findIndex(c => c.fonction === newContact.fonction);
    indexContact > -1 ? actualContacts[indexContact] = { ...newContact } : actualContacts.push({ ...newContact });
}

function updateContactSuccess(state, newContact) {
    const { contacts } = state;
    updateContact(contacts, newContact);
    return updateObject(state, {
        contacts
    });
}

// Fonction de mise à jour de plusieurs contacts
function updateContactsSuccess(state, newContacts) {
    const { contacts } = state;
    newContacts.forEach(c => updateContact(contacts, c));
    return updateObject(state, {
        contacts
    });
}

function updateContactMail(state, action) {
    const contacts = state.contacts;
    const indexContact = contacts.findIndex(c => c.fonction === action.code);
    return updateObject(state, {
        contacts: [
            ...contacts.slice(0, indexContact),
            {
                ...contacts[indexContact],
                email: action.email,
                mailValide: action.mailValide
            },
            ...contacts.slice(indexContact + 1)
        ]
    });
}

function updateSkipStep(state, action) {
    const { stepSkipped, stepToSkip } = action;
    const steps = state.steps;
    const indexStepToSkip = steps.indexOf(stepToSkip);
    const indexStepToSkipPresent = indexStepToSkip !== -1;

    // Si on choisi de sauter l'étape et que l'étape est présente alors on la supprime des étapes à faire
    if (stepSkipped && indexStepToSkipPresent) {
        steps.splice(indexStepToSkip, 1);
    }
    // A l'inverse si on ne saute pas l'étape et que l'étape n'est pas présente alors on l'ajoute
    if (!stepSkipped && !indexStepToSkipPresent) {
        steps.splice(steps.indexOf(MON_PROFIL_STEP_RDP) + 1, 0, stepToSkip);
    }
    return updateObject(state, { skipStep: stepSkipped, steps });
}

function updateNextStep(state) {
    const { steps } = state;
    const indexLastValidatedtStep = steps.indexOf(state.lastValidatedStepPremiereCo);
    const currentIndexStep = steps.indexOf(state.currentStepPremiereCo);
    const nextStepIndex = currentIndexStep + 1;
    const changes = {};

    if (indexLastValidatedtStep < currentIndexStep) {
        changes.lastValidatedStepPremiereCo = steps[currentIndexStep];
    }
    if (nextStepIndex < steps.length) {
        changes.currentStepPremiereCo = steps[nextStepIndex];
    }
    return updateObject(state, changes);
}

function updatePreviousStep(state) {
    const { steps } = state;
    const previousStepIndex = steps.indexOf(state.currentStepPremiereCo) - 1;
    if (previousStepIndex >= 0) {
        return updateObject(state, { currentStepPremiereCo: steps[previousStepIndex] });
    }
    return state;
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
    case actionTypes.GET_MON_PROFIL_SUCCESS:
        return getMonProfilSuccess(state, action.datas);
    case actionTypes.UPDATE_LOADING_MON_PROFIL:
        return updateObject(state, { loading: action.loading });
    case actionTypes.SAVE_MON_PROFIL_COMPTE_UTILISATEUR_SUCCESS:
        // Action.datas contient un objet compteUtilisateur rempli
        return updateObject(state, { compteUtilisateur: action.datas });
    case actionTypes.SAVE_MON_PROFIL_CONTACT_SUCCESS:
        return updateContactSuccess(state, { ...action.contact });
    case actionTypes.SAVE_MON_PROFIL_CONTACTS_SUCCESS:
        return updateContactsSuccess(state, [...action.contacts]);
    case actionTypes.CHECK_MAIL_TOKEN_SUCCESS:
        return updateObject(state, { isMailChecked: action.isMailChecked });
    case actionTypes.UPDATE_VUE_CROISEMENT_SUCCESS:
        return updateObject(state, {
            infoSofia: {
                ...state.infoSofia,
                deltaDetaille: action.canAccess
            }
        });
    case actionTypes.UPDATE_MON_PROFIL_DATA_NOT_SAVED:
        return updateObject(state, { dataNotSaved: action.dataNotSaved });
    case actionTypes.SET_STEP_FORCAGE_PREMIERE_CO:
        return updateObject(state, { currentStepPremiereCo: action.step });
    case actionTypes.SET_LAST_VALIDATED_STEP_PREMIERE_CO:
        return updateObject(state, { lastValidatedStepPremiereCo: action.step });
    case actionTypes.SET_STEPS_FORCAGE_PREMIERE_CO:
        return updateObject(state, { steps: action.steps });
    case actionTypes.GO_TO_NEXT_STEP_PREMIERE_CONNEXION:
        return updateNextStep(state);
    case actionTypes.GO_TO_PREVIOUS_STEP_PREMIERE_CONNEXION:
        return updatePreviousStep(state);
    case actionTypes.UPDATE_SKIP_STEP_PREMIERE_CONNEXION:
        return updateSkipStep(state, action);
    case actionTypes.UPDATE_MON_PROFIL_CONTACT_MAIL_SUCESS:
        return updateContactMail(state, action);
    case actionTypes.UPDATE_TOKEN_RESET_MDP_CHECKED:
        return updateObject(state, { tokenResetMdpChecked: action.tokenResetMdpChecked });
    default:
        return state;
    }
};

export default reducer;
