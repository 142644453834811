import axios from "axios";

export const onChangePwdUnconnected = (gencod, newPassword) => axios
    .post("/password/public/resetUnconnected", null, {
        params: {
            gencod,
            newPassword
        }
    });

export const onChangePwdConnected = (gencod, newPassword) => axios
    .post("/password/updateMotDePasseConnected", null, {
        params: {
            gencod,
            newPassword
        }
    });

export const checkToken = (gencod, token) => axios.get("/password/public/checkToken", { params: { gencod, token } });
