import { push } from "connected-react-router";
import { call, put, select } from "redux-saga/effects";
import { onChangePwdConnected, onChangePwdUnconnected, checkToken } from "../../../services/resetMdp.service";
import * as actions from "../../actions/index.action";
import { PATH_LOGIN } from "../../../routes/paths/paths.util";
import { selectUtilisateurInformations } from "../../../utils/selectors";
import { FAIL_CHANGE_PWD, LIBELLE_MON_PROFIL_ERREUR_CHECK_MAIL, NO_USER_CHANGE_PWD, SUCCESS_CHANGE_PWD } from "../../../utils/libelleConstantes";


export function *changePwdUnconnectedSaga(action) {
    const gencod = action.gencod;
    const password = action.password;

    try {
        const response = yield onChangePwdUnconnected(gencod, password);
        if (response.data) {
            yield put(actions.openDialog(SUCCESS_CHANGE_PWD));
            yield put(actions.onChangeLogin(gencod));
            yield put(push(PATH_LOGIN));
        } else {
            yield put(actions.openDialog(NO_USER_CHANGE_PWD));
        }
    } catch (error) {
        yield put(actions.openDialogNoRedirect(FAIL_CHANGE_PWD, error.message));
    }
}

export function *changePwdConnectedSaga({ password, premiereCo }) {
    const infos = yield select(selectUtilisateurInformations);
    const gln = infos.gencod;
    try {
        const response = yield call(onChangePwdConnected, gln, password);
        if (response.data) {
            if (premiereCo) {
                yield put(actions.goToNextStepPremiereConnexion());
            } else {
                yield put(actions.openDialog(SUCCESS_CHANGE_PWD));
            }
        } else {
            yield put(actions.openDialog(NO_USER_CHANGE_PWD));
        }
    } catch (error) {
        yield put(actions.openDialogNoRedirect(FAIL_CHANGE_PWD, error.message));
    }
}

export function *checkTokenSaga({ gencod, token }) {
    try {
        const response = yield call(checkToken, gencod, token);

        yield put(actions.updateTokenResetMdpSuccess(response.data));
    } catch (error) {
        yield put(actions.openDialogNoRedirect(LIBELLE_MON_PROFIL_ERREUR_CHECK_MAIL, error.message));
    }
}
